import stylesEvents from "./events.module.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import fxexample from "../../Assets/photos/ftexmpl.png";
import photoex1 from "../../Assets/photos/photo-ex-1.png";
import photoex2 from "../../Assets/photos/photo-ex-2.png";
import photoex4 from "../../Assets/photos/scenario-events.png";

function Events() {
  return (
    <>
      <Header />

      <figure className={stylesEvents["wrap-figure"]}>
        <div className={stylesEvents["wrap-txt-figure"]}>
          <p>Eventos</p>
        </div>
      </figure>

      <section className={stylesEvents["section-1"]}>
        <div className={stylesEvents["wrap-txt-section-1"]}>
          <p className="font-g">O lugar dos sonhos para o seu eventos</p>
          <p className="font-sm">
            No coração da praia mais famosa do Rio de Janeiro, o Copacabana
            Palace é o que mais se destaca quando o assunto é *evento*. Planeje
            conosco seu casamento, festa especial ou reunião de negócios em um
            dos 13 salões exclusivos do hotel. Nossos espaços sofisticados e
            equipe especializada ajudarão a adicionar brilho extra à sua
            celebração.
          </p>
          <p className="fonnt-s line-button-right">
            Entre em contato com os nossos especialistas
          </p>
        </div>
      </section>

      <section className={`${stylesEvents["section-3"]} container`}>
        <div className={stylesEvents["wrap-content-section-3"]}>
          <div className={stylesEvents["wrap-img-section-3"]}>
            <img
              className={stylesEvents["img-section-3"]}
              src={photoex2}
              alt=""
            />
          </div>
          <div className={stylesEvents["wrap-txt-section-3"]}>
            <p className="font-m txt-end">TEATRO COPACABANA PALACE</p>
            <p className="font-xs">
              Após uma restauração minuciosa e espetacular, o icônico Teatro
              Copacabana Palace reabre suas cortinas.
            </p>
            <p className="font-s txt-end line-button-left">Saiba mais</p>
          </div>
        </div>
      </section>

      <section className={`${stylesEvents["section-4"]} container`}>
        <div className={stylesEvents["wrap-content-section-4"]}>
          <div className={stylesEvents["wrap-txt-section-4"]}>
            <p className="font-m txt-end">O melhor dia da sua vida</p>
            <p className="font-xs">
              Coração palpitante, emoção a flor da pele e recordações
              inesquecíveis. Estes são os sintomas de um casamento perfeito aqui
              no Copa. Seja com uma grande festa ou com uma recepção intimista,
              você e seus convidados vão sentir todo o encanto dos nossos
              majestosos salões. Independentemente do que escolher, nossa equipe
              está preparada para superar suas expectativas e te ajudar em cada
              detalhe que precisar. Temos certeza que será memorável!
            </p>
            <p className="font-s txt-end line-button-right">
            Fale conosco
            </p>
          </div>
          <div className={stylesEvents["wrap-img-section-4"]}>
            <img
              className={stylesEvents["img-section-4"]}
              src={photoex1}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className={`${stylesEvents["section-3"]} container`}>
        <div className={stylesEvents["wrap-content-section-3"]}>
          <div className={stylesEvents["wrap-img-section-3"]}>
            <img
              className={stylesEvents["img-section-3"]}
              src={photoex2}
              alt=""
            />
          </div>
          <div className={stylesEvents["wrap-txt-section-3"]}>
            <p className="font-m txt-end">Enfim, sós</p>
            <p className="font-xs">
            Areia dourada e mar azul. Acordar com a vista deslumbrante da Praia de Copacabana ao lado de quem você ama é uma cena que vai repassar muitas vezes na sua lembrança. Aproveite dias incríveis para desbravar o que o Rio tem de melhor e descanse no nosso SPA ou na famosa piscina do Copa rodeada por três restaurantes e um Pool Bar. Gastronomia, com certeza, será um ponto alto da sua viagem, pois somos o único hotel da América do Sul com dois restaurantes com Estrela Michelin.
            </p>
            <p className="font-s txt-end line-button-left">
            Fale Conosco
            </p>
          </div>
        </div>
      </section>

      <section className={`${stylesEvents["section-4"]} container`}>
        <div className={stylesEvents["wrap-content-section-4"]}>
          <div className={stylesEvents["wrap-txt-section-4"]}>
            <p className="font-m txt-end">Perfeito em cada detalhe</p>
            <p className="font-xs">
            O Rio já é um lugar inspirador por natureza. Agora imagine isso somado à atmosfera majestosa do Copacabana Palace. Todas as metas serão batidas. Se você está à procura de um destino para conferências ou reuniões de incentivo, temos tudo para oferecer ao seu negócio nos nossos 13 espaços de eventos, dos mais variados formatos e tamanhos. Combinando nossa clássica grandiosidade com serviço de excelência, nós cuidamos de todos os detalhes para tornar seu encontro um sucesso.
            </p>
            <p className="font-s txt-end line-button-right">
            Fale conosco
            </p>
          </div>
          <div className={stylesEvents["wrap-img-section-4"]}>
            <img
              className={stylesEvents["img-section-4"]}
              src={photoex1}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className={stylesEvents["section-5"]}>
        <div className={stylesEvents["wrap-contents-section-5"]}>
          <div className={stylesEvents["wrap-txt-section-5"]}>
            <p className="font-g">Descubra nossos lugares</p>
            <p className="font-s-bold">
              Defina o cenário para uma comemoração inesquecível
            </p>
          </div>
          <div className="img-section-5">
            <img src={photoex4} alt="" />
          </div>
          <div className={stylesEvents["wrap-txt-2-section-5"]}>
            <p className="font-s">O lugar perfeito para o seu evento</p>
            <p className="font-sm">
              De uma reunião mais íntima até grandes eventos, temos o espaço
              ideal para a sua reunião ou comemoração.
            </p>
            <p className="fon-s line-button-right">Mais detalhes dos salões</p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Events;
