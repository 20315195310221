import React, { createContext, useState, useContext, useMemo } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const value = useMemo(() => ({
    isSidebarOpen,
    setIsSidebarOpen,
  }), [isSidebarOpen]);

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  
  );
};

export const useSidebar = () => {
  return useContext(SidebarContext);
};
