import stylesActivities from "./activities.module.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import photoex1 from "../../Assets/photos/photo-ex-1.png";
import photoex2 from "../../Assets/photos/photo-ex-2.png";
import photoex3 from "../../Assets/photos/scenario-4.png"

function activities() {
  return (
    <>
      <Header />

      <figure className={stylesActivities["wrap-figure"]}>
        <div className={stylesActivities["wrap-txt-figure"]}>
          <p>Atividades</p>
        </div>
      </figure>

      <section className={stylesActivities["section-1"]}>
        <div className={stylesActivities["wrap-txt-section-1"]}>
          <p className="font-g">
            Descubra diversas atividades para fazer em Rurópolis
          </p>
          <p className="font-sm">
            Descubra a Cidade Maravilhosa a partir do Copacabana Palace de
            formas que você nunca imaginou. De aventuras pelo mar a tentadoras
            imersões culinárias, não faltam atividades para fazer no Rio de
            Janeiro.
          </p>
        </div>
      </section>

      <section className={`${stylesActivities["section-2"]} container`}>
        <div className={stylesActivities["wrap-content-section-2"]}>
          <div className={stylesActivities["wrap-txt-section-2"]}>
            <p className="font-m txt-end">O Rio Pelo Mar</p>
            <p className="font-xs">
              Embarque em um veleiro privativo ou compartilhado pela Baía de
              Guanabara e praias da região para apreciar as belezas naturais do
              Rio, de um ângulo exclusivo. O passeio começa na Marina da Glória
              e saí rumo às praias da Zona Sul e da cidade de Niterói, com pausa
              para um energizante mergulho. Outro ponto que até os cariocas amam
              visitar é o famoso arquipélago das Ilhas Cagarras, lindo para
              descansar e apreciar a vista da natureza e do mar azul. O retorno
              do passeio é feito ao final da tarde, para comtemplar o pôr do sol
              com o Cristo Redentor abençoando.
            </p>
            <p className="font-s txt-end line-button-right">
              Fale concosco para verificar tarifas e disponibilidade
            </p>
          </div>
          <div className={stylesActivities["wrap-img-section-2"]}>
            <img
              className={stylesActivities["img-section-2"]}
              src={photoex1}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className={`${stylesActivities["section-3"]} container`}>
        <div className={stylesActivities["wrap-content-section-3"]}>
          <div className={stylesActivities["wrap-img-section-3"]}>
            <img
              className={stylesActivities["img-section-3"]}
              src={photoex2}
              alt=""
            />
          </div>
          <div className={stylesActivities["wrap-txt-section-3"]}>
            <p className="font-m txt-end">TOUR ARQUITETÔNICO E CULTURAL</p>
            <p className="font-xs">
              Relaxe ao sol, dê um mergulho e aproveite para socializar em uma
              das maiores e mais desejadas piscinas do Rio de Janeiro. Nossos
              funcionários estarão à disposição ao longo do dia para atender a
              todos os seus pedidos, trazendo desde toalhas limpas até bebidas
              refrescantes e pratos leves do menu da piscina.
            </p>
            <p className="font-s txt-end line-button-left">
              Reserve seu tour arquitetônico
            </p>
          </div>
        </div>
      </section>

      <section className={`${stylesActivities["section-4"]} container`}>
        <div className={stylesActivities["wrap-content-section-4"]}>
          <div className={stylesActivities["wrap-txt-section-4"]}>
            <p className="font-m txt-end">Clássicos Paraenses</p>
            <p className="font-xs">
              Visitar o Rio sem conhecer o Cristo Redentor e o Pão de açúcar,
              tornará experiência carioca incompleta.
            </p>
            <p className="font-xs">
              O Cristo Redentor, localizado no coração do Rio de Janeiro, é um
              dos nossos destaques. Considerado uma das 7 Maravilhas do Mundo
              Moderno, você terá uma experiência única de ver a Cidade
              Maravilhosa por um ângulo privilegiado, aos pês do Cristo. Em
              seguida, não perca a oportunidade de contemplar o pôr do sol mais
              cobiçado da cidade.
            </p>
            <p className="font-s txt-end line-button-right">
              Fale concosco para verificar tarifas e disponibilidade
            </p>
          </div>
          <div className={stylesActivities["wrap-img-section-4"]}>
            <img
              className={stylesActivities["img-section-4"]}
              src={photoex1}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className={stylesActivities["section-5"]}>
        <div className={stylesActivities["wrap-contents-section-5"]}>
        <div className={stylesActivities["wrap-txt-section-5"]}>
          <p className="font-g">Pacotes & experiências</p>
          <p className="font-s-bold">
            Festeje no hotel mais estrelado do Rio antes de explorar as
            florestas tropicais de Iguaçu. A América do Sul é repleta de
            possibilidades—para aonde você vai?
          </p>
        </div>
        <div className="img-section-5">
          <img src={photoex3} alt="" />
        </div>
        <div className={stylesActivities["wrap-txt-2-section-5"]}>
          <p className="font-s">5 noites: Rio de Janeiro e Iguaçu</p>
          <p className="font-m">Grand Tour pelo Brasil</p>
          <p className="fon-s line-button-right">Ver itinerário</p>
        </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default activities;
