import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import stylesGallery from "./gallery.module.css"

function gallery(){
    return(
    <>
    <Header />
    
    <section className={stylesGallery["section-1"]}>
        <div className={stylesGallery["wrap-txt-section-1"]}>
            <p className="font-g">Uma curta descrição do Nosso Mundo</p>
            <p className="font-sm">Pelas fotos para uma amostra do que te espera em Copcacabana Palace</p>
        </div>
    </section>
    
    
    
    
    
    <Footer />
    </>
    )
};

export default gallery;