import stylesTheHotel from "./thehotel.module.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import photoex1 from "../../Assets/photos/photo-ex-1.png";
import photoex2 from "../../Assets/photos/photo-ex-2.png";
import photoex3 from "../../Assets/photos/photo-ex-3.png";
import photoex4 from "../../Assets/photos/photo-ex-4.png"
import icons from "../../styles/icons";

function theHotel() {
  return (
    <>
      <Header />

      <figure className={stylesTheHotel["wrap-figure-the-hotel"]}>
        <div className={stylesTheHotel["wrap-txt-figure--the-hotel"]}>
          <p>o hotel</p>
        </div>
      </figure>

      <section className={stylesTheHotel["section-1-the-hotel"]}>
        <div className={stylesTheHotel["background-section-1-the-hotel"]}>
          <div className={stylesTheHotel["txt-section-1-the-hotel"]}>
            <p className="font-m">
              refúgio atemporal no coração da cidade maravilhosa
            </p>
            <span className="font-mm">
              O Copacabana Palace é o verdadeiro oásis no meio da Praia de
              Copacabana. Aqui os hóspedes têm a privacidade que desejam,
              serviço de qualidade e conforto em todos os ambientes.
            </span>
            <span className="font-mm">
              Além disso, nossa equipe irá te proporcionar as melhores
              experiências dentro e fora do hotel. Venha viver a emoção de se
              hospedar no hotel mais emblemático do Rio de Janeiro!
            </span>
          </div>
        </div>
      </section>

      <section className={stylesTheHotel["section-2-the-hotel"]}>
        <div className={stylesTheHotel["background-section-2-the-hotel"]}>
          <div className={stylesTheHotel["txt-section-2-the-hotel"]}>
            <p className="font-m">resumo</p>
            <span>acomodação 239 apartamentos e suites</span>
            <span>
              REFEIÇÕES Somos o único hotel na América Latina com dois
              restaurantes com Estrela Michelin: MEE e Ristorante Hotel
              Cipriani. O restaurante Pérgula completa o pólo gastronômico do
              hotel com comida internacional.
            </span>
            <span>
              DESTAQUES Piscina semiolímpica, Copacabana Palace Spa, fitness
              center, quadra de tênis
            </span>
            <span>ATIVIDADES Tours pela cidade exclusivos</span>
            <span>
              EVENTOS 13 salões para eventos com capacidade para até 2.000
              pessoas
            </span>
            <span>
              LOCALIZAÇÃO A 28 km do Aeroporto Internacional do Rio de Janeiro
            </span>
          </div>
        </div>
      </section>

      <section className={stylesTheHotel["section-3-the-hotel"]}>
        <div className={stylesTheHotel["txt-section-3-the-hotel"]}>
          <p className="font-g">diferente. melhor. especial</p>
          <span className="font-s-lowercase">
            Se hospedar no Copa faz da viagem ao Rio de Janeiro uma experiência
            única. Abaixo listamos apenas alguns serviços que nos fazem destacar
            dos demais.
          </span>
        </div>
      </section>

      <section className={`${stylesTheHotel["section-4-the-hotel"]} container`}>
        <div className={stylesTheHotel["wrap-content-section-4-the-hotel"]}>
          <div className={stylesTheHotel["wrap-txt-section-4-the-hotel"]}>
            <p className="font-m txt-end">seu escritório no rio</p>
            <p className="font-xs">
              Uma mesa com vista para a praia de Copacabana. Uma ida até a
              piscina para um mergulho refrescante e coquetéis. Que dia de
              trabalho! Troque seu escritório diário por uma estadia em nosso
              oásis urbano. Você se sentirá inspirado desde o momento de sua
              chegada.
            </p>
            <p className="font-s txt-end line-button-right">saiba mais</p>
          </div>
          <div className={stylesTheHotel["wrap-img-section-4-the-hotel"]}>
            <img className={stylesTheHotel["img-section-4-the-hotel"]} src={photoex1} alt="" />
          </div>
        </div>
      </section>

      <section className={`${stylesTheHotel["section-5-the-hotel"]} container`}>
        <div className={stylesTheHotel["wrap-content-section-5-the-hotel"]}>
          <div className={stylesTheHotel["wrap-img-section-5-the-hotel"]}>
            <img className={stylesTheHotel["img-section-5-the-hotel"]} src={photoex2} alt="" />
          </div>
          <div className={stylesTheHotel["wrap-txt-section-5-the-hotel"]}>
            <p className="font-m txt-end">piscina</p>
            <p className="font-xs">
              Relaxe ao sol, dê um mergulho e aproveite para socializar em uma
              das maiores e mais desejadas piscinas do Rio de Janeiro. Nossos
              funcionários estarão à disposição ao longo do dia para atender a
              todos os seus pedidos, trazendo desde toalhas limpas até bebidas
              refrescantes e pratos leves do menu da piscina.
            </p>
            <p className="font-s txt-end line-button-left">saiba mais</p>
          </div>
        </div>
      </section>

      <section className={`${stylesTheHotel["section-6-the-hotel"]} container`}>
        <div className={stylesTheHotel["wrap-content-section-6-the-hotel"]}>
          <div className={stylesTheHotel["wrap-txt-section-6-the-hotel"]}>
            <p className="font-m txt-end">Serviço de praia</p>
            <p className="font-xs">
              Desça até a famosa Praia de Copacabana, bem em frente ao hotel, e
              desfrute as comodidades que oferecemos na areia da praia. Nosso
              serviço exclusivo é sazonal e acontece de acordo com a
              disponibilidade. Ele contempla espreguiçadeiras, guarda-sóis,
              bola, frescobol e água para todos os hóspedes do hotel.
            </p>
            <p className="font-xs">
              A equipe de praia também cuidará de seus pertences se você quiser
              mergulhar nas águas azuis.
            </p>
            <p className="font-s txt-end line-button-right">saiba mais</p>
          </div>
          <div className={stylesTheHotel["wrap-img-section-6-the-hotel"]}>
            <img className={stylesTheHotel["img-section-6-the-hotel"]} src={photoex3} alt="" />
          </div>
        </div>
      </section>

      <section className={stylesTheHotel["section-7-the-hotel"]}>
        <div className={stylesTheHotel["background-section-7-the-hotel"]}>
          <div className={stylesTheHotel["wrap-content-section-7-the-hotel"]}>
            <div>
              <img className="icons-sm" src={icons.star} alt="" />
            </div>
            <div className={stylesTheHotel["wrap-txt-section-7-the-hotel"]}>
              <p className="font-m">tênis</p>
              <p className="font-xs">
                Mantenha-se em forma ou alivie o stress com uma partida de tênis
                em nossa quadra. Fale com nosso concierge para providenciar
                raquetes e bolas de uso gratuito. Como alternativa, melhore seu
                saque ou treine seu backhand com a ajuda de um treinador
                profissional (preço disponível mediante solicitação).
              </p>
              <p className="font-s txt-end line-button-left">Fale conosco</p>
            </div>
            <div>
              <img className="icons-sm" src={icons.star} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className={`${stylesTheHotel["section-8-the-hotel"]} container`}>
        <div className={stylesTheHotel["wrap-content-section-8-the-hotel"]}>
          <div className={stylesTheHotel["wrap-txt-section-8-the-hotel"]}>
            <p className="font-m txt-end">ESTACIONAMENTO</p>
            <p className="font-xs">
              Informamos que não possuímos garagem no hotel. Abaixo seguem os
              serviços de Valet Parking disponíveis.
            </p>
            <p className="font-xs">
              Para estacionar o seu veículo em local aberto, na Avenida
              Atlântica, com manobrista: R$60,00 + taxas por dia.
            </p>
            <p className="font-xs">
              Para estacionar o seu veículo em local coberto próximo ao hotel,
              com manobrista: R$120,00 + taxas por dia (de acordo com
              disponibilidade).
            </p>
            <p className="font-s txt-end line-button-right">saiba mais</p>
          </div>
          <div className={stylesTheHotel["wrap-img-section-8-the-hotel"]}>
            <img className={stylesTheHotel["img-section-8-the-hotel"]} src={photoex4} alt="" />
          </div>
        </div>
      </section>

      <section className={stylesTheHotel["section-9-the-hotel"]}>
      <div className={stylesTheHotel["wrap-contents-section-9-the-hotel"]}>
        <div className={stylesTheHotel["wrap-content-1-section-9-the-hotel"]}>
          <img className="icons-g" src={icons.question} alt="" />
          <span>Perguntas Frequentes</span>
          <p className="font-mm">Consulte nossas perguntas frequentes antes da sua estadia no Copacabana Palace.</p>
          <p className="font-s txt-end line-button-left">Saiba mais</p>
        </div>
        <div className={stylesTheHotel["wrap-content-2-section-9-the-hotel"]}>
        <img className="icons-g" src={icons.phone} alt="" />
          <span>Fale Conosco</span>
          <p className="font-mm">Entre em contato e comece a planejar o seu sonho de férias no Rio de Janeiro no Copacabana Palace.</p>
          <p>55 93 9999 9999</p>
          <p className="font-s txt-end line-button-left">Entrar em contato</p>
        </div>
      </div>
      </section>

      <Footer />
    </>
  );
}

export default theHotel;
